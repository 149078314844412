<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordionType = accordionType === 0 ? false : 0"
      >
        <h5 class="m-0">Detalle de servicio</h5>
      </CButton>
      <CCollapse :show="accordionType === 0">
        <CCardBody>
          <b-row>           
            <!-- Service -->
            <b-col lg="6">
              <b-form-group>
                Servicio *
                <v-select
                  :options="servicesOptions"
                  v-model="payment.serviceSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid':
                      $v.payment.serviceSelected.$error,
                    'form-control is-valid': !$v.payment.serviceSelected
                      .$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.serviceSelected.required"
                >
                  Debes seleccionar un servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Type -->
            <b-col lg="6">
              <b-form-group>
                Tipo *
                <v-select
                  :options="typeOptions"
                  v-model="payment.typeSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid': $v.payment.typeSelected.$error,
                    'form-control is-valid': !$v.payment.typeSelected.$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.typeSelected.required"
                >
                  Debes seleccionar un servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Quantity -->
            <b-col sm="6">
              <b-form-group>
                Cantidad de servicios *
                <b-form-input
                  id="quantity"
                  type="number"
                  min="1"
                  placeholder=""
                  v-model.trim="$v.payment.quantity.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.quantity.$error,
                    'form-control is-valid': !$v.payment.quantity.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.quantity.required">
                  Debes indicar la ctdad de servicios.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- pets -->
            <b-col sm="6">
              <b-form-group>
                Cantidad de Mascotas *
                <b-form-input
                  id="pets"
                  type="number"
                  min="1"
                  placeholder=""
                  v-model.trim="$v.payment.pets.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.pets.$error,
                    'form-control is-valid': !$v.payment.pets.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.pets.required">
                  Debes indicar la ctdad de servicios.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Date start service -->
            <b-col sm="6">
              Fecha de inicio del servicio *
              <b-form-group>
                <b-form-datepicker
                  id="serviceStartDate"
                  placeholder="dd/mm/yyyy"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  locale="es"
                  v-model.trim="$v.payment.serviceStartDate.$model"
                  :class="{
                    'form-control is-invalid':$v.payment.serviceStartDate.$error,
                    'form-control is-valid': !$v.payment.serviceStartDate.$invalid
                  }"
                  today-button
                  reset-button
                >                  
                </b-form-datepicker>
                <b-form-invalid-feedback v-if="!$v.payment.serviceStartDate.required">
                  La fecha de inicio del servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Plan price filtered -->
            <b-col sm="6">
              <b-form-group v-if="planInfo[0] != null">
                Precio und:
                <b-form-input
                  type="number"
                  id="quantity"
                  min="0"
                  placeholder=""
                  v-model="planInfo[0].precio"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
            <br>
          </b-row>
          <b-row>
            <b-col lg="6">
              
              <b-button @click="submit">
                Agregar
              </b-button>              
            </b-col>
            <b-col lg="6" v-if="flagBtnShow">
              <b-button @click="showServicesFixed">
                Ver servicios
              </b-button>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";
import { uuid } from "uuidv4";
import firebase from 'firebase';
export default {
  name: "paymentHeader",
  props: ["plans", "payer", "clientSchedule", "collectionFee"],
  data() {
    return {
      accordionHeader: 0,
      accordionType: 0,
      //Err
      flagErr: false,
      errMsg: "",
      //Header
      uuidValue: null,
      
      payment: {
        serviceSelected: null,
        typeSelected: null,
        quantity: null, //number of services
        pets: null,
        serviceStartDate: null,
        collectionFee: 0,
      },
      //Type of service
      servicesOptions: [
        { value: null, label: "SELECCIONE" },
        { value: 1, label: "Paseo" },
        { value: 2, label: "Adiestramiento" },
        { value: 35, label: "Guardería" },
        { value: 68, label: "Conducta" }
      ],
      typeOptions: [
        { value: null, label: "SELECCIONE" },
        { value: 1, label: "Grupal" },
        { value: 2, label: "Dedicado" }
      ],
      order: [],
      flagBtnShow: false
    };
  },
  created() {
    this.uuidValue = uuid();
  },
  validations: {
    payment: {
      serviceSelected: {
        required
      },
      typeSelected: {
        required
      },
      pets: {
        required
      },
      quantity: {
        required
      },
      serviceStartDate: {
        required
      },

      collectionFee: {},
    }
  },
  computed: {
    planInfo() {
      let service = this.payment.serviceSelected;
      let quantity = this.payment.quantity; //num of Services
      let pets = this.payment.pets;
      let type = this.payment.typeSelected;
      if (!this.$v.payment.$invalid) {
        this.$store.commit("defPayment", this.payment);

        let plansFiltered = this.plans.filter(
          item =>
            item.servicio == service.label &&
            +item.mascotas == pets &&
            +item.horas_min <= quantity &&
            +item.horas_max >= quantity &&
            item.tipo == type.label
        );
        // this.$store.commit("defPlansFiltered", plansFiltered);
        // this.createOrderItem();
        return plansFiltered;
      } else {
        this.$v.$touch();
        // this.$store.commit("defPlansFiltered", null);
        // this.order = []
        // this.$store.commit("defOrder", this.order)
        return "Invalido";
      }
    },
    clientHasSchedule(){
      if(this.clientSchedule != null){
        let activeSchedule = this.clientSchedule.dates.filter(date => date.active == true)
        if(activeSchedule.length > 0){
          return true
        } else {
          return false
        }
      } else {
        return false
      }

    }
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.payment.$invalid) {
        this.errMsg = "Completa los del servicio";
        this.flagErr = true;
        this.order = [];
        this.$store.commit("defOrder", this.order);
      } 
      // if (this.$v.payment.img && this.$v.payment.amount <= 0) {
      //   this.errMsg = "Monto total no válido para registrar un pago";
      //   this.flagErr = true;
      //   this.order = [];
      //   this.$store.commit("defOrder", this.order);
      // }

      else {
        this.flagErr = false;
        this.createOrderItem();
      }
    },
    async createOrderItem() {
      // console.log(this.planInfo[0]);
      try {

        let itemOrder = {
          pedido: this.setPedido(),
          servicio: this.setServices()
        }
         console.log('clientHasSchedule: ', this.clientHasSchedule);
        if(this.clientHasSchedule) {
          console.log('clientHasSchedule');
          const payload = {
            clientSchedule: this.clientSchedule,
            order: itemOrder
          };

          let servicesFixed = await this.setScheduledServices(payload)
          console.log(servicesFixed);
          if(servicesFixed){
            itemOrder["servicio"] = servicesFixed;
            this.order.push(itemOrder);
            this.$store.commit("defRequestedService", itemOrder.pedido);
            this.$store.commit("defOrder", this.order);
            this.$store.commit("defShowOrderModal", true);
            this.flagBtnShow = true;
          }

          // let servicesFixSchedule = this.$firebase
          //     .functions()
          //     .httpsCallable("servicesFixSchedule");

          // servicesFixSchedule(payload).then(res => {
          //   let servicesFixed = res.data.data;
          //   itemOrder["servicio"] = servicesFixed;
          //   this.order.push(itemOrder);
          //   this.$store.commit("defOrder", this.order);
          //   this.$store.commit("defShowOrderModal", true);
          //   this.flagBtnShow = true;
          // }).catch(error => {
          //   console.log("Err header: ", error);
          // });
        } 
        else {
            this.order.push(itemOrder);
            console.log(itemOrder);
            this.$store.commit("defRequestedService", itemOrder.pedido);
            this.$store.commit("defOrder", this.order);
            this.$store.commit("defShowOrderModal", true);
        }
        
      } catch (error) {
        console.log('Error createOrderItem');
        console.log(error);
      }
      
      



      // this.order.push(orderItem)

     
      // let date1 = new Date();
      // const key = "696_17839_" + date1.getTime();
      // const fecha_pago = this.payment.date == null
      //   ? null : moment(this.payment.date).format("yyyy-MM-DD") 
      //     + "T00:00:00.000Z"
            
      // let itemOrder = {
      //   pedido: {
      //     key,
      //     medio: this.payment.methodSelected,
      //     pedido_estado: { id: this.payment.statusSelected.value},
      //     fecha_operacion: fecha_pago,
      //     fecha_caducidad: moment(date1)
      //       .add('days', this.planInfo[0].caducidad)
      //       .format("yyyy-MM-DD") + "T00:00:00.000Z",
      //     precio_und: this.planInfo[0].precio,
      //     // precio: this.payment.amount,
      //     precio: Number(this.planInfo[0].precio) * Number(this.payment.quantity),
      //     debe: this.payment.owes,
      //     pagado: 0,
      //     transaccion_id: this.uuidValue,
      //     pedido_id: this.payment.code ? this.payment.code : "",

      //     articulo_id:
      //       this.payment.serviceSelected.label + " " + this.planInfo[0].tipo,
      //     cantidad: this.payment.quantity,
      //     servicios_restantes: this.payment.quantity,
      //     fecha_inicio: this.payment.serviceStartDate,
      //     moneda: "COP",

      //     nombre: this.payer.clientName,
      //     email: this.payer.client.email,
      //     cliente: {
      //       id: this.payer.client.id
      //     },
      //     mascota: {
      //       id: this.payer.id
      //     },
      //     comentario: this.payment.comments
      //   },
      //   servicio: []
      // };
     
      // itemOrder.pedido["planes"] = {
      //   id: this.planInfo[0].id
      // };
     

      // for (let index = 0; index < this.payment.quantity; index++) {
      //   let date = new Date();
      //   let obs = index + 1;
      //   let service = {
      //     key: "696_17839_" + date.getTime() + index,
      //     mascota: {
      //       id: this.payer.id
      //     },
      //     obs_cliente:
      //       this.payment.serviceSelected.label +
      //       " " +
      //       obs +
      //       "/" +
      //       this.payment.quantity,
      //     fecha_inicio: null,
      //     fecha_fin: null
      //   };

      //   itemOrder["servicio"].push(service);
      // }


      // let payload = {
      //   clientSchedule: this.clientSchedule,
      //   order: itemOrder
      // };

      // if (this.clientHasSchedule) {
      //   let servicesFixSchedule = this.$firebase.functions().httpsCallable("servicesFixSchedule");
      //   servicesFixSchedule(payload).then(res => {
      //     let servicesFixed = res.data.data;
      //     itemOrder["servicio"] = servicesFixed;
      //     this.order.push(itemOrder);
      //     this.$store.commit("defOrder", this.order);
      //     this.$store.commit("defShowOrderModal", true);
      //     this.flagBtnShow = true;
      //   }).catch(error => {
      //     console.log("Err header: ", error);
      //   });
      // } else {
      //     this.order.push(itemOrder);
      //     this.$store.commit("defOrder", this.order);
      //     this.$store.commit("defShowOrderModal", true);
      // }

    },
    setPedido(){

      let pedido = {
        ////from payment
        // medio: this.payment.methodSelected,
        // pedido_estado: { id: this.payment.statusSelected.value},
        // fecha_operacion: fecha_pago,
        // debe: this.payment.owes,
        // transaccion_id: this.uuidValue, //not being used
        // pagado: 0,
        // pedido_id: this.payment.code ? this.payment.code : "",
        // comentario: this.payment.comments,

        key: this.getKey(),
        articulo_id: this.getArticuloId(),
        cantidad: +this.payment.quantity,
        fecha_inicio: this.payment.serviceStartDate,
        moneda: "COP",
        precio_und: +this.planInfo[0].precio,
        precio: Number(this.planInfo[0].precio) * Number(this.payment.quantity),

        nombre: this.payer.clientName,
        email: this.payer.client.email,
        cliente: {
          id: this.payer.client.id
        },
        mascota: {
          id: this.payer.id
        },
        
        servicios_restantes: +this.payment.quantity,
        fecha_caducidad: moment(new Date())
          .add('days', this.planInfo[0].caducidad)
          .format("yyyy-MM-DD") + "T00:00:00.000Z",
        planes: {id: this.planInfo[0].id},
        pago_ids: [],
      }
      return pedido
    },
    setServices() {
      let services = []
      const quantity = this.payment.quantity;
      for (let index = 0; index < quantity; index++) {
        let service = {
          key: this.getKey() + index,
          mascota: { id: this.payer.id },
          obs_cliente: this.setServiceObservation(index),
          fecha_inicio: null,
          fecha_fin: null
        };
        services.push(service);
      }
      return services
    },
    setScheduledServices(payload){
      return new Promise((resolve, reject) => {
        let servicesFixSchedule = this.$firebase
            .functions()
            .httpsCallable("servicesFixSchedule");

        servicesFixSchedule(payload).then(res => {
          const servicesFixed = res.data.data;
          resolve(servicesFixed)        
        }).catch(error => {
          console.log("Err setting services with scheduled: ", error);
          resolve(null)
        });
      })
    },
    getKey(){
      const date = new Date();
      const key = "696_17839_" + date.getTime();
      return key
    },
    getArticuloId(){
      return this.payment.serviceSelected.label + " " + this.planInfo[0].tipo
    },
    setServiceObservation(index) {
      const text =  
        `${this.payment.serviceSelected.label} ${index+1}/${this.payment.quantity},`
      return text
    },
    showServicesFixed() {
      this.$store.commit("defShowOrderModal", true);
    },
    
    getPaymentDates(date) {
      let dates = {
        year: moment(date).format('YYYY'),
        month: moment(date).format('MMMM'),
        day: moment(date).format('DD'),
      };
      return dates
    },
    createInvoiceImg(year,month, fileName, file){
      return new Promise((resolve, reject) => {
        let fileTask = firebase.storage().ref(`invoices/${year}/${month}/${fileName}`).put(file)
        fileTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        } , function(error) {
          reject(error)
        }, async function() {
          let downloadURL = await fileTask.snapshot.ref.getDownloadURL()
            // Upload completed successfully, now we can get the download URL
          resolve(downloadURL)
        });
      })
    },
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
   
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordionHeader = accordionHeader === 0 ? false : 0"
      >
        <h5 class="m-0">Descuentos por crédito</h5>
      </CButton>
      <CCollapse :show="accordionHeader === 0">
        <CCardBody>
           <loading
            :active.sync="loadingCredits"
            :can-cancel="false"
            background-color="#fff"
            :is-full-page="false"
          >
          </loading>
          <!-- {{ items }} -->
          <b-table
            :items="items"
            :fields="fields"
            stacked="md"
            show-empty
            small
          >
            <template #cell(aplicar)="row">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  v-model="row.item.aplicar"
                  @change="
                    applyCredit(row.item, row.index, $event.target)"
                />
              </div>
            </template>
            
          </b-table>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
export default {
  name: "ClientResumen",
  components: {Loading},
  data() {
    return {
      accordionHeader: 0,
      accordionType: 0,
      fields: [
        { key: "id", label: "id crédito" },
        { key: "valor_disponible", label: "Vlr disponible" },
        { key: "aplicar", label: "Aplicar dscto" }
      ],
      items: [],
      loadingCredits: false
    };
  },
  created() {
    this.watchs();
  },
  methods: {
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defClientCredits") {
          this.items = this.$store.getters.clientCredits;
          this.loadingCredits = false
        }
      });
    },
    applyCredit(item) {
      let creditsToApply = this.items.filter(credit => credit.aplicar === true)
      this.$store.commit('defClientCreditsToApply', creditsToApply);
    },
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <b-modal
      title="Programación de servicios"
      v-model="showOrderModal"
      no-close-on-backdrop
      hide-header-close
      size="xl"
    >
      <div v-for="(orderItem, i) in order" v-bind:key="i">
        <b-row>
          <b-col lg="4" v-for="(servicio, i2) in orderItem.servicio" v-bind:key="i2">
            <CCard class="mb-0" style="margin-top:0.5rem;">
              <CCardBody>
                <p><strong>Servicio: </strong>  {{servicio.obs_cliente}}</p>
                <span v-if="servicio.fecha_inicio != null && servicio.fecha_fin != null">
                  <CIcon name="cilCalendar" size="sm" height="48" alt="Logo" /> {{servicio.fecha_inicio | formatDateTime1}} <br>
                  <CIcon name="cilClock" size="sm" height="48" alt="Logo" />  {{servicio.fecha_inicio | formatDateTime3}} - {{servicio.fecha_fin | formatDateTime3}}
                </span>
                <p v-if="servicio.usuario"><strong>Proveedor: </strong>  {{servicio.usuario.fullname}}</p>
              </CCardBody>
            </CCard>
          </b-col>
        </b-row>
      </div>

      <template #modal-footer>
        <div class="w-100">
          <b-row>
            <b-col lg="12">
              <b-button size="md" class="float-right" @click="hideModal">
                Cerrar
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "servicesModal",
  props: ["showOrderModal"],
  data() {
    return {
      order: null
    };
  },
  created() {
    this.order = this.$store.getters.order;
    this.watchs();
  },
  methods: {
    hideModal() {
      this.$store.commit("defShowOrderModal", false);
    },
    watchs() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defOrder") {
          this.order = this.$store.getters.order;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>

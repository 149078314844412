<template>
  <div>
    <br />
    <b-row v-for="(date, iDates) in dates" v-bind:key="iDates">
      <b-col lg="2">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="gridCheck"
            v-model="date.active"
          />
          <label class="form-check-label" for="gridCheck">
            {{ date.day }}
          </label>
        </div>
      </b-col>

      <b-col lg="2">
        <CButton
          color="success"
          size="sm"
          variant="ghost"
          @click="addTime(date)"
        >
          <CIcon name="cil-plus" />
        </CButton>
      </b-col>      
      <b-col lg="12">
        <p></p>
        <p v-if="!date.active" style="color:#ccc">No disponible</p>
        <b-row v-for="(item, iTimes) in date.times" v-bind:key="iTimes">
          <b-col lg="3">
            <b-form-group>
              <v-select
                :options="times"
                v-model="item.start"
                @input="startTimeChanged($event, date, iTimes)"
              >
              </v-select>
            </b-form-group>
          </b-col>
          -
          <b-col lg="3">
            <b-form-group>
              <v-select
                :options="times"
                v-model="item.end"
                @input="endTimeChanged($event, date, iTimes)"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="">
            <b-form-group>
              <v-select
                :options="walkersOpts"
                v-model="item.walker"
                @input="setWalker($event, date, iTimes)"
              >
              </v-select>
            </b-form-group>
          </b-col>
          <b-col lg="1">
            <CButton
              color="danger"
              size="sm"
              variant="ghost"
              @click="deleteTime(date.times, iTimes, date)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </b-col>
        </b-row>
      </b-col>

      

      <b-col lg="12">
        <hr />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import times from "../../../../utils/times";
export default {
  name: "scheduleDays",
  props: ["schedule"],
  data() {
    return {
      dates: [
        {
          day: "lun.",
          times: [],
          active: false
        },
        {
          day: "mar.",
          times: [],
          active: false
        },
        {
          day: "mié.",
          times: [],
          active: false
        },
        {
          day: "jue.",
          times: [],
          active: false
        },
        {
          day: "vie.",
          times: [],
          active: false
        },
        {
          day: "sáb.",
          times: [],
          active: false
        },
        {
          day: "dom.",
          times: [],
          active: false
        }
      ],
      times: times,
      clientSchedule: null,
      walkers: null
    };
  },
  created() {
    this.schedule.dates = this.dates
    this.$store.commit('defClientSchedule', this.schedule)
    this.watchs()
  },
  computed: {
    walkersOpts(){
      let data = []
      if (this.walkers!= null) {
        this.walkers.map((walker) => {
          let item = {
            value: walker.id,
            label: walker.fullname,
          };
          data.push(item)
        })
        return data
      }else{
        return null
      }
    }
  },
  methods: {
    watchs(){
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'defClientSchedule') {
          this.clientSchedule = this.$store.getters.clientSchedule;
          if(this.clientSchedule != null){
            this.dates = this.clientSchedule.dates;
            this.schedule.id = this.clientSchedule.id != null ? this.clientSchedule.id : null
          }
        }
        if (mutation.type === 'defWalkers') {
          this.walkers = this.$store.getters.walkers;
        }
      })
    },
    startTimeChanged(time, date, i) {
      let length = this.schedule.length.value;
      let timeFound = null;
      if (length == 1) {
        // 45 min
        timeFound = times.find(t => t.value == time.value + 3);
      } else if (length == 2) {
        // 1 hour
        timeFound = times.find(t => t.value == time.value + 4);
      }
      date.times[i].end = timeFound;
      this.schedule.dates = this.dates
      if(this.clientSchedule != null){
        this.schedule.id = this.clientSchedule.id != null ? this.clientSchedule.id : null
      }else {
        this.schedule.id = null
      }
      this.$store.commit("defClientSchedule", this.schedule)
    },
    endTimeChanged(time, date, i) {
      // console.log("endTime");
      // console.log(time);
      // console.log(date.day);
    },
    addTime(date) {
      date.active = true;
      let item = {
        start: null,
        end: null,
        walker: null
      };
      date.times.push(item);
    },
    deleteTime(times, i, date) {
      times.splice(i,1)
      if(times.length == 0)
        date.active = false
      this.schedule.dates = this.dates
      this.schedule.id = this.clientSchedule.id != null ? this.clientSchedule.id : null
      this.$store.commit("defClientSchedule", this.schedule)
    },
    setWalker(){

    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div v-if="payer">
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CCardHeader>
        <strong>Detalle de cliente</strong>
      </CCardHeader>
      <CCardBody>
        <b-row>
          <b-col lg="2">
            <p><strong>Id cliente:</strong> {{ payer.client.id }}</p>
          </b-col>
          <b-col lg="5">
            <p><strong>Cliente:</strong> {{ payer.clientName }}</p>
          </b-col>
          <b-col lg="5">
            <p><strong>Mascota:</strong> {{ payer.name }}</p>
          </b-col>
        </b-row>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
export default {
  name: 'clientInfo',
  props: ['payer']
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordionHeader = accordionHeader === 0 ? false : 0"
      >
        <h5 class="m-0">Datos de pago</h5>
      </CButton>
      <CCollapse :show="accordionHeader === 0">
        <CCardBody>        
          <b-alert v-model="flagErr" variant="danger" dismissible>
            {{ errMsg }}
          </b-alert>
          <b-row>
            <b-col lg="12" >              
              <b-form-group style="text-align: center">
                <img 
                  :src="payment.url ? payment.url : 'https://firebasestorage.googleapis.com/v0/b/test-goguau.appspot.com/o/general%2Finvoice.jpg?alt=media&token=926c0acc-b1bd-48a1-a5eb-3be3beada190'"
                  alt="" 
                  class="invoice-img">       
                <b-form-file
                  v-model.trim="$v.payment.img.$model"
                  placeholder="Cargar img de recibo .jpg, .png, .jpeg"
                  drop-placeholder="Coloca tu foto aquí..."
                  accept=".jpg, .png, .jpeg"
                  browse-text="Buscar"
                  @input="updateURL"
                  >
                </b-form-file>                 
              </b-form-group>                                            
            </b-col>
          </b-row>
          <br>
     
          <b-row>
            <!-- Payment method -->
            <b-col lg="6" v-if="$v.payment.img.$model != null">
              <b-form-group>
                <label for="method">Método de pago *</label>
                <b-form-select
                  id="method"
                  :plain="true"
                  :options="methodOptions"
                  v-model.trim="$v.payment.methodSelected.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.methodSelected.$error,
                    'form-control is-valid': !$v.payment.methodSelected.$invalid
                  }"
                >
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.payment.methodSelected.required">
                  El método de pago es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Date of payment -->
            <b-col sm="6" v-if="$v.payment.img.$model != null">
              Fecha de pago *
              <b-form-group>
                <b-form-datepicker
                  id="paymentDate"
                  placeholder="dd/mm/yyyy"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  locale="es"
                  v-model.trim="$v.payment.date.$model"
                  :class="{
                    'form-control is-invalid':$v.payment.date.$error,
                    'form-control is-valid': !$v.payment.date.$invalid
                  }"
                  today-button
                  reset-button
                >                  
                </b-form-datepicker>
                <b-form-invalid-feedback v-if="!$v.payment.date.required">
                  La fecha de pago del servicio es obligatoria
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Code -->
            <b-col lg="6" v-if="$v.payment.img.$model != null">
              Id de pago *
              <b-form-group>
                <b-form-input 
                  type="text" 
                  v-model.trim="$v.payment.code.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.code.$error,
                    'form-control is-valid': !$v.payment.code.$invalid
                  }">
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.code.required">
                  Inserta el id de pago
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          
            <!-- Status -->
            <b-col lg="6">
              <b-form-group>
                Estado del pago *
                <v-select
                  :options="statusOptions"
                  v-model="payment.statusSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid': $v.payment.statusSelected.$error,
                    'form-control is-valid': !$v.payment.statusSelected.$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.statusSelected.required"
                >
                  El estado del pago es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Amount -->
            <b-col sm="6">
              <b-form-group>
                Monto recibido ($) *
                <b-form-input
                  type="number"
                  id="amount"
                  placeholder="Ingresa valor"
                  v-model.trim="$v.payment.amount.$model"
                  min="0"
                  :class="{
                    'form-control is-invalid': $v.payment.amount.$error,
                    'form-control is-valid': !$v.payment.amount.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.amount.required">
                  El monto total es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Owns -->
            <b-col sm="6">
              <b-form-group>
                Debe ($) *
                <b-form-input
                  id="amount"
                  type="number"
                  min="0"
                  placeholder="Ingresa valor"
                  v-model.trim="$v.payment.owes.$model"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <!-- comments -->
            <b-col lg="12">
              Comentarios
              <CTextarea
                placeholder="Cualquier anotación que desees registrar..."
                rows="2"
                v-model="payment.comments"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button           
                variant="warning" 
                class="w-100"
                @click="submit">
                CONFIRMAR
              </b-button>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>

  </div>
</template>

<script>
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";
import { uuid } from "uuidv4";
import firebase from 'firebase';
export default {
  name: "paymentHeader",
  props: ["plans", "payer", "clientSchedule", "collectionFee"],
  data() {
    return {
      accordionHeader: 0,
      accordionType: 0,
      //Err
      flagErr: false,
      errMsg: "",
      //Header
      uuidValue: null,
      methodOptions: ["Efectivo", "Bancolombia", "Daviplata", "Nequi"],
      statusOptions: [
        {value: 1, label: "Pendiente"}, 
        {value:2, label: 'Recibido'}
      ],
      payment: {
        methodSelected: null,
        statusSelected: null,
        date: null,
        amount: null, //price
        owes: 0, //debe
        code: null,
        comments: "",
  
      },
      
      order: [],
      flagBtnShow: false
    };
  },
  created() {
    this.uuidValue = uuid();
  },
  validations: {
    payment: {
      statusSelected: {
        required
      },
      methodSelected: {
        required: requiredIf(function(){
          if(this.$v.payment.img.$model != null) return true;
          return false;
        })
      },
      date: {
        required: requiredIf(function(){
          if(this.$v.payment.img.$model != null) return true;
          return false;
        })
      },
      amount: {
        required
      },
      comments: {},
      owes: {},
      code: {
         required: requiredIf(function(){
          if(this.$v.payment.img.$model != null) return true;
          return false;
        })
      },
      img: { },
    }
  },
 
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.payment.$invalid) {
        this.errMsg = "Completa los campos de pago";
        this.flagErr = true;
        this.order = [];
        this.$store.commit("defOrder", this.order);
      } 
      if (this.$v.payment.img && this.$v.payment.amount <= 0) {
        this.errMsg = "Monto total no válido para registrar un pago";
        this.flagErr = true;
        this.order = [];
        this.$store.commit("defOrder", this.order);
      }

      else {
        this.flagErr = false;
        // this.createOrderItem();
        this.setPaymentDTO()
      }
    },
    async setPaymentDTO(){
      let paymentDTO = {
        key: this.getKey(),
        medio: this.payment.methodSelected,
        fecha_pago: this.setPaymentDate(),
        id_transaccion: this.payment.code ? this.payment.code : "",
        img_recibo: null,
        comentario: this.payment.comments,

        cliente: {id: this.payer.client.id},
        mascota: {id: this.payer.id},
          
          pedido_estado: { id: this.payment.statusSelected.value},//Colocarlo en pedido
          debe: +this.payment.owes,
        //To implement
        tarifa_recaudo: 0,
        valor: +this.payment.amount, //Take value from Resumen
       
      }

      if(this.payment.img) {
          this.payment.url = URL.createObjectURL(this.payment.img)
          paymentDTO.img_recibo = await this.postInvoceImage() 
      }

      if(this.payment.methodSelected === 'Efectivo') {
        paymentDTO.tarifa_recaudo = this.collectionFee
      }
      console.log(paymentDTO);
      this.$store.commit("defPaymentDTO", paymentDTO)
    },
    getKey(){
      const date = new Date();
      const key = "696_17839_" + date.getTime();
      return key
    },
    setPaymentDate(){
      const payment_date = this.payment.date == null
        ? null : moment(this.payment.date).format("yyyy-MM-DD") 
          + "T00:00:00.000Z"
      return payment_date
    },
    
    async postInvoceImage() {
      
      let {year, month, day} = this.getPaymentDates(this.payment.date)

      const newFileName = `${month}${day}-${this.payer.client.id}-${this.payment.code}`
      const file = this.payment.img;

      return this.createInvoiceImg(year, month, newFileName, file)
    },
    getPaymentDates(date) {
      let dates = {
        year: moment(date).format('YYYY'),
        month: moment(date).format('MMMM'),
        day: moment(date).format('DD'),
      };
      return dates
    },
    createInvoiceImg(year,month, fileName, file){
      return new Promise((resolve, reject) => {
        let fileTask = firebase.storage().ref(`invoices/${year}/${month}/${fileName}`).put(file)
        fileTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        } , function(error) {
          reject(error)
        }, async function() {
          let downloadURL = await fileTask.snapshot.ref.getDownloadURL()
            // Upload completed successfully, now we can get the download URL
          resolve(downloadURL)
        });
      })
    },

   
   
    updateURL(){                        
      let url = URL.createObjectURL(this.payment.img)
      this.payment['url'] = url
      url = null
    },  
  
  }
};
</script>

<style lang="scss" scoped></style>

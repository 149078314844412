<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordionHeader = accordionHeader === 0 ? false : 0"
      >
        <h5 class="m-0">Datos de pago</h5>
      </CButton>
      <CCollapse :show="accordionHeader === 0">
        <CCardBody>        
          <b-alert v-model="flagErr" variant="danger" dismissible>
            {{ errMsg }}
          </b-alert>
          <b-row>
            <b-col lg="12" >              
              <b-form-group style="text-align: center">
                <img 
                  :src="payment.url ? payment.url : 'https://firebasestorage.googleapis.com/v0/b/test-goguau.appspot.com/o/general%2Finvoice.jpg?alt=media&token=926c0acc-b1bd-48a1-a5eb-3be3beada190'"
                  alt="" 
                  class="invoice-img">       
                <b-form-file
                  v-model.trim="$v.payment.img.$model"
                  placeholder="Cargar img de recibo .jpg, .png, .jpeg"
                  drop-placeholder="Coloca tu foto aquí..."
                  accept=".jpg, .png, .jpeg"
                  browse-text="Buscar"
                  @input="updateURL"
                  >
                </b-form-file>                 
              </b-form-group>                                            
            </b-col>
          </b-row>
          <br>
     
          <b-row>
            <!-- Payment method -->
            <b-col lg="6" v-if="$v.payment.img.$model != null">
              <b-form-group>
                <label for="method">Método de pago *</label>
                <b-form-select
                  id="method"
                  :plain="true"
                  :options="methodOptions"
                  v-model.trim="$v.payment.methodSelected.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.methodSelected.$error,
                    'form-control is-valid': !$v.payment.methodSelected.$invalid
                  }"
                >
                </b-form-select>
                <b-form-invalid-feedback v-if="!$v.payment.methodSelected.required">
                  El método de pago es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Date of payment -->
            <b-col sm="6" v-if="$v.payment.img.$model != null">
              Fecha de pago *
              <b-form-group>
                <b-form-datepicker
                  id="paymentDate"
                  placeholder="dd/mm/yyyy"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  locale="es"
                  v-model.trim="$v.payment.date.$model"
                  :class="{
                    'form-control is-invalid':$v.payment.date.$error,
                    'form-control is-valid': !$v.payment.date.$invalid
                  }"
                  today-button
                  reset-button
                >                  
                </b-form-datepicker>
                <b-form-invalid-feedback v-if="!$v.payment.date.required">
                  La fecha de pago del servicio es obligatoria
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Code -->
            <b-col lg="6" v-if="$v.payment.img.$model != null">
              Id de pago *
              <b-form-group>
                <b-form-input 
                  type="text" 
                  v-model.trim="$v.payment.code.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.code.$error,
                    'form-control is-valid': !$v.payment.code.$invalid
                  }">
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.code.required">
                  Inserta el id de pago
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          
            <!-- Status -->
            <b-col lg="6">
              <b-form-group>
                Estado del pago *
                <v-select
                  :options="statusOptions"
                  v-model="payment.statusSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid': $v.payment.statusSelected.$error,
                    'form-control is-valid': !$v.payment.statusSelected.$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.statusSelected.required"
                >
                  El estado del pago es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Amount -->
            <b-col sm="6">
              <b-form-group>
                Monto recibido ($) *
                <b-form-input
                  type="number"
                  id="amount"
                  placeholder="Ingresa valor"
                  v-model.trim="$v.payment.amount.$model"
                  min="0"
                  :class="{
                    'form-control is-invalid': $v.payment.amount.$error,
                    'form-control is-valid': !$v.payment.amount.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.amount.required">
                  El monto total es obligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Owns -->
            <b-col sm="6">
              <b-form-group>
                Debe ($) *
                <b-form-input
                  id="amount"
                  type="number"
                  min="0"
                  placeholder="Ingresa valor"
                  v-model.trim="$v.payment.owes.$model"
                >
                </b-form-input>
              </b-form-group>
            </b-col>

            <!-- comments -->
            <b-col lg="12">
              Comentarios
              <CTextarea
                placeholder="Cualquier anotación que desees registrar..."
                rows="2"
                v-model="payment.comments"
              />
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordionType = accordionType === 0 ? false : 0"
      >
        <h5 class="m-0">Tipo de servicio</h5>
      </CButton>
      <CCollapse :show="accordionType === 0">
        <CCardBody>
          <b-row>           
            <!-- Service -->
            <b-col lg="6">
              <b-form-group>
                Servicio *
                <v-select
                  :options="servicesOptions"
                  v-model="payment.serviceSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid':
                      $v.payment.serviceSelected.$error,
                    'form-control is-valid': !$v.payment.serviceSelected
                      .$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.serviceSelected.required"
                >
                  Debes seleccionar un servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Type -->
            <b-col lg="6">
              <b-form-group>
                Tipo *
                <v-select
                  :options="typeOptions"
                  v-model="payment.typeSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid': $v.payment.typeSelected.$error,
                    'form-control is-valid': !$v.payment.typeSelected.$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.typeSelected.required"
                >
                  Debes seleccionar un servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Quantity -->
            <b-col sm="6">
              <b-form-group>
                Cantidad de servicios *
                <b-form-input
                  id="quantity"
                  type="number"
                  min="1"
                  placeholder=""
                  v-model.trim="$v.payment.quantity.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.quantity.$error,
                    'form-control is-valid': !$v.payment.quantity.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.quantity.required">
                  Debes indicar la ctdad de servicios.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- pets -->
            <b-col sm="6">
              <b-form-group>
                Cantidad de Mascotas *
                <b-form-input
                  id="pets"
                  type="number"
                  min="1"
                  placeholder=""
                  v-model.trim="$v.payment.pets.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.pets.$error,
                    'form-control is-valid': !$v.payment.pets.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.pets.required">
                  Debes indicar la ctdad de servicios.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Date start service -->
            <b-col sm="6">
              Fecha de inicio del servicio *
              <b-form-group>
                <b-form-datepicker
                  id="serviceStartDate"
                  placeholder="dd/mm/yyyy"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  }"
                  locale="es"
                  v-model.trim="$v.payment.serviceStartDate.$model"
                  :class="{
                    'form-control is-invalid':$v.payment.serviceStartDate.$error,
                    'form-control is-valid': !$v.payment.serviceStartDate.$invalid
                  }"
                  today-button
                  reset-button
                >                  
                </b-form-datepicker>
                <b-form-invalid-feedback v-if="!$v.payment.serviceStartDate.required">
                  La fecha de inicio del servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Plan price filtered -->
            <b-col sm="6">
              <b-form-group v-if="planInfo[0] != null">
                Precio und:
                <b-form-input
                  type="number"
                  id="quantity"
                  min="0"
                  placeholder=""
                  v-model="planInfo[0].precio"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
            <br>
          </b-row>
          <b-row>
            <b-col lg="6">
              
              <b-button @click="submit">
                Agregar
              </b-button>              
            </b-col>
            <b-col lg="6" v-if="flagBtnShow">
              <b-button @click="showServicesFixed">
                Ver servicios
              </b-button>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import moment from "moment";
import { required, requiredIf } from "vuelidate/lib/validators";
import { uuid } from "uuidv4";
import firebase from 'firebase';
export default {
  name: "paymentHeader",
  props: ["plans", "payer", "clientSchedule", "collectionFee"],
  data() {
    return {
      accordionHeader: 0,
      accordionType: 0,
      //Err
      flagErr: false,
      errMsg: "",
      //Header
      uuidValue: null,
      methodOptions: ["Efectivo", "Bancolombia", "Daviplata", "Nequi"],
      statusOptions: [
        {value: 1, label: "Pendiente"}, 
        {value:2, label: 'Recibido'}
      ],
      payment: {
        methodSelected: null,
        statusSelected: null,
        date: null,
        amount: null, //price
        owes: 0, //debe
        code: null,
        comments: "",

        serviceSelected: null,
        typeSelected: null,
        quantity: null, //number of services
        pets: null,
        serviceStartDate: null,
        img: null,
        collectionFee: 0,
      },
      //Type of service
      servicesOptions: [
        { value: null, label: "SELECCIONE" },
        { value: 1, label: "Paseo" },
        { value: 2, label: "Adiestramiento" },
        { value: 35, label: "Guardería" }
      ],
      typeOptions: [
        { value: null, label: "SELECCIONE" },
        { value: 1, label: "Grupal" },
        { value: 2, label: "Dedicado" }
      ],
      typePaymentProviderSelected: 1,
      typePaymentProviderOptions: [
        { item: 1, name: "Comisión" },
        { item: 2, name: "Porcentaje" }
      ],
      order: [],
      flagBtnShow: false
    };
  },
  created() {
    this.uuidValue = uuid();
    if(!this.payer) this.$router.push({ path: "/financial/payments" });
  },
  validations: {
    payment: {
      statusSelected: {
        required
      },
      methodSelected: {
        required: requiredIf(function(){
          if(this.$v.payment.img.$model != null) return true;
          return false;
        })
      },
      date: {
        required: requiredIf(function(){
          if(this.$v.payment.img.$model != null) return true;
          return false;
        })
      },
      amount: {
        required
      },
      comments: {},
      owes: {},
      code: {
         required: requiredIf(function(){
          if(this.$v.payment.img.$model != null) return true;
          return false;
        })
      },
      serviceSelected: {
        required
      },
      typeSelected: {
        required
      },
      pets: {
        required
      },
      quantity: {
        required
      },
      serviceStartDate: {
        required
      },
      img: { },
      collectionFee: {},
    }
  },
  computed: {
    planInfo() {
      let service = this.payment.serviceSelected;
      let quantity = this.payment.quantity; //num of Services
      let pets = this.payment.pets;
      let type = this.payment.typeSelected;
      if (!this.$v.payment.$invalid) {
        this.$store.commit("defPayment", this.payment);

        let plansFiltered = this.plans.filter(
          item =>
            item.servicio == service.label &&
            +item.mascotas == pets &&
            +item.horas_min <= quantity &&
            +item.horas_max >= quantity &&
            item.tipo == type.label
        );
        this.$store.commit("defPlansFiltered", plansFiltered);
        // this.createOrderItem();
        return plansFiltered;
      } else {
        this.$v.$touch();
        // this.$store.commit("defPlansFiltered", null);
        // this.order = []
        // this.$store.commit("defOrder", this.order)
        return "Invalido";
      }
    },
    clientHasSchedule(){
      if(this.clientSchedule != null){
        let activeSchedule = this.clientSchedule.dates.filter(date => date.active == true)
        if(activeSchedule.length > 0){
          return true
        } else {
          return false
        }
      } else {
        return false
      }

    }
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.payment.$invalid) {
        this.errMsg = "Completa los campos de pago";
        this.flagErr = true;
        this.order = [];
        this.$store.commit("defOrder", this.order);
      } 
      if (this.$v.payment.img && this.$v.payment.amount <= 0) {
        this.errMsg = "Monto total no válido para registrar un pago";
        this.flagErr = true;
        this.order = [];
        this.$store.commit("defOrder", this.order);
      }

      else {
        this.flagErr = false;
        this.createOrderItem();
      }
    },
    createOrderItem() {
      let date1 = new Date();
      const key = "696_17839_" + date1.getTime();
      const fecha_pago = this.payment.date == null
        ? null : moment(this.payment.date).format("yyyy-MM-DD") 
          + "T00:00:00.000Z"
            
      let itemOrder = {
        pedido: {
          key,
          medio: this.payment.methodSelected,
          pedido_estado: { id: this.payment.statusSelected.value},
          fecha_operacion: fecha_pago,
          fecha_caducidad: moment(date1)
            .add('days', this.planInfo[0].caducidad)
            .format("yyyy-MM-DD") + "T00:00:00.000Z",
          precio_und: this.planInfo[0].precio,
          // precio: this.payment.amount,
          precio: Number(this.planInfo[0].precio) * Number(this.payment.quantity),
          debe: this.payment.owes,
          pagado: 0,
          transaccion_id: this.uuidValue,
          pedido_id: this.payment.code ? this.payment.code : "",

          articulo_id:
            this.payment.serviceSelected.label + " " + this.planInfo[0].tipo,
          cantidad: this.payment.quantity,
          servicios_restantes: this.payment.quantity,
          fecha_inicio: this.payment.serviceStartDate,
          moneda: "COP",

          nombre: this.payer.clientName,
          email: this.payer.client.email,
          cliente: {
            id: this.payer.client.id
          },
          mascota: {
            id: this.payer.id
          },
          comentario: this.payment.comments
        },
        servicio: []
      };
     
      // if (this.typePaymentProviderSelected == 1) {
      itemOrder.pedido["planes"] = {
        id: this.planInfo[0].id
      };
      // } else if (this.typePaymentProviderSelected == 2) {
      //   itemOrder.pedido["planes"] = {
      //     id: 38 // plan id 38 "otro 80%"
      //   };
      // }

      // let itemOrderFound = this.order.find(
      //   itemOrder => itemOrder.pedido.tarifa_recaudo > 0
      // );

      // if (itemOrderFound == undefined) {
      //   itemOrder.pedido["tarifa_recaudo"] =
      //     this.payment.methodSelected == "Efectivo" ? this.collectionFee : 0;
      // } else {
      //   itemOrder.pedido["tarifa_recaudo"] = 0;
      // }

      for (let index = 0; index < this.payment.quantity; index++) {
        let date = new Date();
        let obs = index + 1;
        let service = {
          key: "696_17839_" + date.getTime() + index,
          mascota: {
            id: this.payer.id
          },
          obs_cliente:
            this.payment.serviceSelected.label +
            " " +
            obs +
            "/" +
            this.payment.quantity,
          fecha_inicio: null,
          fecha_fin: null
        };

        itemOrder["servicio"].push(service);
      }
      // console.log('itemOrder: ', itemOrder)
      // console.log('order')
      // console.log(itemOrder)
      // console.log(this.order)
      // console.log('clientSchedule')
      // console.log(this.clientSchedule)

      let payload = {
        clientSchedule: this.clientSchedule,
        order: itemOrder
      };
      // console.log('payload:', payload)

      if (this.clientHasSchedule) {
        let servicesFixSchedule = this.$firebase.functions().httpsCallable("servicesFixSchedule");
        servicesFixSchedule(payload).then(res => {
          let servicesFixed = res.data.data;
          itemOrder["servicio"] = servicesFixed;
          this.order.push(itemOrder);
          this.$store.commit("defOrder", this.order);
          this.$store.commit("defShowOrderModal", true);
          this.flagBtnShow = true;
        }).catch(error => {
          console.log("Err header: ", error);
        });
      } else {
          this.order.push(itemOrder);
          this.$store.commit("defOrder", this.order);
          this.$store.commit("defShowOrderModal", true);
      }

    },
    showServicesFixed() {
      this.$store.commit("defShowOrderModal", true);
    },
    updateURL(){                        
      let url = URL.createObjectURL(this.payment.img)
      this.payment['url'] = url
      url = null
      
    },  
    async postInvoceImage() {
      
      let {year, month, day} = this.getPaymentDates(this.payment.date)

      const newFileName = `${this.payer.client.id}-${month}${day}-${this.payment.code}`
      const file = this.payment.img;

      return this.createInvoiceImg(year, month, newFileName, file)
    },
    getPaymentDates(date) {
      let dates = {
        year: moment(date).format('YYYY'),
        month: moment(date).format('MMMM'),
        day: moment(date).format('DD'),
      };
      return dates
    },
    createInvoiceImg(year,month, fileName, file){
      return new Promise((resolve, reject) => {
        let fileTask = firebase.storage().ref(`invoices/${year}/${month}/${fileName}`).put(file)
        fileTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        } , function(error) {
          reject(error)
        }, async function() {
          let downloadURL = await fileTask.snapshot.ref.getDownloadURL()
            // Upload completed successfully, now we can get the download URL
          resolve(downloadURL)
        });
      })
    },
  }
};
</script>

<style lang="scss" scoped></style>

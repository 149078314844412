<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordion = accordion === 0 ? false : 0"
      >
        <h5 class="m-0">Horario de cliente</h5>
      </CButton>
      <CCollapse :show="accordion === 0">
        <CCardBody>
          <b-row>
            <!-- Service length -->
            <b-col lg="6">
              <b-form-group>
                Duración *
                <v-select
                  :options="lengthOpts"
                  v-model.trim="$v.schedule.length.$model"
                  :class="{
                    'form-control is-invalid': $v.schedule.length.$error,
                    'form-control is-valid': !$v.schedule.length.$invalid
                  }"
                  @input="updateScheduleHeader()"
                  :clearable="false"
                                 
                >
                </v-select>
                <b-form-invalid-feedback v-if="!$v.schedule.length.required">
                  Debes seleccionar una duración
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col lg="6">
              <br>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="holidaysCheck"
                  v-model="schedule.holidays"
                  @change="updateScheduleHeader()"                 
                />
                <label class="form-check-label" for="holidaysCheck">
                  Incluir festivos Lunes-Viernes
                </label>
              </div>
            </b-col>
          </b-row>
          
          <ScheduleDays 
            :schedule="schedule"
            />

          <b-row>
            <b-col lg="12">
              <b-alert 
                v-model="flagErr" 
                :variant="alertVariant" 
                dismissible>
               {{ errMsg }}
              </b-alert>
            </b-col>
            
            <b-col lg="12">
              ¿Deseas fijar este horario al cliente?
            </b-col>
            <b-col lg="12">
              <b-button
                @click="setClientSchedule">
                Si, fijar
              </b-button>
            </b-col>
          </b-row>

        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import ScheduleDays from "./ScheduleDays";
export default {
  name: "schedule",
  components: { ScheduleDays },
  props:["clientId"],
  data() {
    return {
      flagErr: false,
      errMsg: "",
      alertVariant: "success",
      accordion: 0,
      lengthOpts: [
        { value: 1, label: "45 minutos" },
        { value: 2, label: "1 hora" }
      ],
      schedule: {
        length: { value: 1, label: "45 minutos" },
        holidays: true
      },
    };
  },
  validations: {
    schedule: {
      length: {
        required
      }
    }
  },
  created(){
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "defClientSchedule") {
        let clientSchedule = this.$store.getters.clientSchedule;
        if(clientSchedule != null){
          this.schedule = {
            length: clientSchedule.length,
            holidays: clientSchedule.holidays,
          };
        }
      }
    })
  },
  methods: {
    setClientSchedule(){
      let clientSchedule = this.$store.getters.clientSchedule;
      clientSchedule.length = this.schedule.length
      clientSchedule.holidays = this.schedule.holidays
      let payload = {
        clientId: this.clientId,
        clientSchedule
      }
      if(clientSchedule.id != null){
        //  The client has a schedule 
        let updateClientSchedule = this.$firebase.functions().httpsCallable('updateClientSchedule')
        updateClientSchedule(payload).then((res) => {
          this.flagErr = true
          this.errMsg = "¡Horario de cliente actualizado!"
        }).catch((error) => {
          console.log('Err updating schedule: ', error)
        })
      }else {
        // Schedule to be created
        let createClientSchedule = this.$firebase.functions().httpsCallable('createClientSchedule')
        createClientSchedule(payload).then((res) => {
          this.flagErr = true
          this.errMsg = "¡Nuevo horario de cliente creado!"
          let data = res.data.data
          clientSchedule.id = +(data.id)
          this.$store.commit("defClientSchedule", clientSchedule)
        }).catch((error) => {
          console.log('Err creating schedule: ', error)
        })
      }
    },
    updateScheduleHeader(){
      let clientSchedule = this.$store.getters.clientSchedule;
      let schedule = {
        length: this.schedule.length != null ? this.schedule.length: null,
        holidays: this.schedule.holidays != null ? this.schedule.holidays : null,
        dates: clientSchedule.dates != null ? clientSchedule.dates : null,
        id: clientSchedule.id != null ? clientSchedule.id : null ,
      };
      this.$store.commit("defClientSchedule", schedule)
    },    
  },
  
};
</script>

<style lang="scss" scoped></style>
